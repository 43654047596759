












import Vue from 'vue'
import firebase from 'firebase'
import * as firebaseui from 'firebaseui'
const VueC3 = require('vue-c3') // eslint-disable-line @typescript-eslint/no-var-requires
import 'c3/c3.min.css'

const firebaseConfig = {
  apiKey: "AIzaSyDXfbGp8TlLwEY2wvSBHD6JjHxV2LteNBY",
  authDomain: "raspi-iot-4c45c.firebaseapp.com",
  projectId: "raspi-iot-4c45c",
  storageBucket: "raspi-iot-4c45c.appspot.com",
  messagingSenderId: "888762677678",
  appId: "1:888762677678:web:ed346abf4284e3f827f03f",
  measurementId: "G-71BRXWKTNK",
}

const uiConfig = {
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  signInSuccessUrl: '/',
}

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const auth = firebase.auth()
const ui = new firebaseui.auth.AuthUI(auth);
const limit = 10

interface Data {
  handler: Vue
  date: string[]
  airTemp: string[]
  waterTemp: string[]
  airHumid: string[]
  waterEC: string[]
  waterPH: string[]
  user: null | firebase.User
  email: string
  password: string
}

export default Vue.extend({
  name: 'LineChart',
  components: {
    VueC3
  },
  data(): Data {
    return {
      handler: new Vue(),
      date: ['測定日時'],
      airTemp: ['気温'],
      waterTemp: ['水温'],
      airHumid: ['湿度'],
      waterEC: ['EC'],
      waterPH: ['pH'],
      user: null,
      email: '',
      password: '',
    }
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.user = user
        console.log(this.user);
        (async () => {
          await this.streamDocument(db, limit)
          this.displayChart()
        })()
      } else {
        console.log('not logged');
        this.uiLogin()
      }
    })

    // this.login()
  },
  computed: {
    today() {
      return new Date()
    }
  },
  methods: {
    async streamDocument(db: firebase.firestore.Firestore, limit: number) {
      const envRef = db.collection('env')
      const snapshot = await envRef.orderBy('createAt', 'desc').limit(limit).get()
      // this.envDatas = snapshot
      snapshot.forEach(doc => {
        const data = doc.data()
        this.date.push(doc.id)
        this.airTemp.push(data.airTemp)
        this.airHumid.push(data.airHumid)
        this.waterTemp.push(data.waterTemp)
        this.waterEC.push(data.waterEC)
        this.waterPH.push(data.waterPH)
      })
    },
    displayChart() {
      const options = {
        padding: {
          right: 10,
          left: 40
        },
        data: {
          x: '測定日時',
          xFormat: '%Y%m%d-%H%M',
          columns: [
            this.date,
            this.airTemp,
            this.waterTemp,
            this.airHumid,
            this.waterEC,
            this.waterPH,
          ],
          colors: {
            '気温': '#ff7f0e',
            '水温': '#1f77b4',
            '湿度': '#2ca02c',
            'EC': '#d62728',
            'pH': '#9467bd',
          },
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%H:%M'
            }
          },
        },
      }
      this.handler.$emit('init', options)
    },
    async login() {
      auth.signInWithEmailAndPassword(this.email, this.password)
      .then((userCredential) => {
        // Signed in
        // const user = userCredential.user
      })
      .catch((error) => {
        // const errorCode = error.code
        // const errorMessage = error.message
      })
    },
    uiLogin() {
      ui.start('#firebaseui-auth-container', uiConfig)
    },
    logout() {
    firebase.auth().signOut().then(() => {
    // Sign-out successful.
    this.user = null
    ui.start('#firebaseui-auth-container', uiConfig)
    }).catch((error) => {
      // An error happened.
    });
    },
  },
})
