







import Vue from 'vue'
import LineChart from './components/LineChart.vue'

export default Vue.extend({
  name: 'App',
  components: {
    LineChart
  },
  data() {
    return {

    }
  }
});
